<template>
  <v-dialog
    v-model="show_offer_details_dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark class="orange darken-3">
        <v-btn icon dark @click="$emit('close_show_offer_details_dialog')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Offer Details</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <!-- <v-btn dark text @click="dialog = false"> Submit </v-btn> -->
        </v-toolbar-items>
      </v-toolbar>
      <v-container>
        <v-row>
          <v-col>
            <v-simple-table v-if="dialog_offer_data" id="offer_table" dense>
              <tr>
                <td :width="250">Created</td>
                <td>{{ dialog_offer_data.createdAt | dateFormat }}</td>
              </tr>
              <tr>
                <td>Company</td>
                <td>{{ dialog_offer_data.company.name }}</td>
              </tr>
              <tr>
                <td>Account</td>
                <td>
                  {{
                    dialog_offer_data.account
                      ? dialog_offer_data.account.type === "Cash"
                        ? "Cash"
                        : dialog_offer_data.account.name
                      : ""
                  }}
                </td>
              </tr>
              <tr>
                <td>Amount</td>
                <td>{{ dialog_offer_data.mortgage_amount | formatPrice }}</td>
              </tr>
              <tr>
                <td>Interest Rate</td>
                <td>{{ dialog_offer_data.interest_rate }}%</td>
              </tr>
              <tr>
                <td>Lender Fee</td>
                <td>{{ dialog_offer_data.lender_fee }}%</td>
              </tr>
              <tr>
                <td>Position</td>
                <td>{{ dialog_offer_data.mortgage_position }}</td>
              </tr>
              <tr>
                <td>Term</td>
                <td>{{ dialog_offer_data.mortgage_term }} Months</td>
              </tr>
              <tr>
                <td>Term Type</td>
                <td>
                  {{
                    dialog_offer_data.term_type
                      ? dialog_offer_data.term_type === "Open - After Months"
                        ? ` Open - After ${
                            dialog_offer_data.number_of_months > 1
                              ? `${dialog_offer_data.number_of_months} Months`
                              : `${dialog_offer_data.number_of_months} Month`
                          }`
                        : `${dialog_offer_data.term_type}`
                      : ""
                  }}
                </td>
              </tr>
              <tr>
                <td>Credit</td>
                <td>{{ dialog_offer_data.credit_check | booleanString }}</td>
              </tr>
              <tr>
                <td>Income</td>
                <td>
                  {{ dialog_offer_data.income_verification | booleanString }}
                </td>
              </tr>
              <tr>
                <td>Strata</td>
                <td>
                  {{ dialog_offer_data.strata | booleanString }}
                </td>
              </tr>
              <tr>
                <td>Comments</td>
                <td>{{ dialog_offer_data.comments }}</td>
              </tr>
              <tr>
                <td>Conditions</td>
                <td>{{ dialog_offer_data.condition }}</td>
              </tr>
              <tr>
                <td>Status</td>
                <td>{{ dialog_offer_data.status }}</td>
              </tr>
            </v-simple-table>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <hr />
            <br />
            <v-btn
              v-if="
                dialog_offer_data && dialog_offer_data.status == 'shortlisted'
              "
              color="grey"
              @click="update_summary_offer_status('under_review')"
            >
              Remove from shortlisted
            </v-btn>
            <v-btn
              v-if="
                dialog_offer_data && dialog_offer_data.status != 'shortlisted'
              "
              color="grey"
              @click="update_summary_offer_status('shortlisted')"
            >
              Short List Offer
            </v-btn>
            <br /><br />
            <p>
              By shortlisting the offer it will change status to shortlisted and
              keep the deal active and others can continue bidding. Shortlisted
              offers will be the only ones sent to the borrower.
            </p>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <hr />
            <br />
            <v-btn
              v-if="dialog_offer_data && dialog_offer_data.status == 'awarded'"
              color="green"
              @click="update_summary_offer_status('under_review')"
            >
              Unaward Offer
            </v-btn>
            <v-btn
              v-if="dialog_offer_data && dialog_offer_data.status != 'awarded'"
              color="green"
              @click="update_summary_offer_status('awarded')"
            >
              Award Offer
            </v-btn>
            <br /><br />
            <p>
              You can award the offer. The status of the deal summary will then
              be set to inactive. Lenders will be unable to submit further
              offers.
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <hr />
            <br />
            <v-btn
              v-if="dialog_offer_data && dialog_offer_data.status != 'hidden'"
              color="red"
              @click="update_summary_offer_status('hidden')"
            >
              Hide Offer From Lenders
            </v-btn>
            <v-btn
              v-if="dialog_offer_data && dialog_offer_data.status == 'hidden'"
              color="red"
              @click="update_summary_offer_status('under_review')"
            >
              Unhide Offer From Lenders
            </v-btn>
            <br /><br />
            <p>
              The offer will be hidden from lenders, incase the offer does not
              make sense, verify info then reactivate.
            </p>
          </v-col>
        </v-row>

        <v-row v-if="error">
          <v-col>
            <v-alert type="error">{{ error }}</v-alert>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <hr />
            <br />
            <v-btn
              @click="$emit('close_show_offer_details_dialog')"
              color="orange"
            >
              Cancel / Close
            </v-btn>
            <br /><br />
            <p>
              Nothing will happen to the offer. You will go back to the deal
              information screen.
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
//TODO send award deal email on award
import filt from "@/plugins/filters.js";
import API from "@/plugins/API.js";

export default {
  props: [
    "show_offer_details_dialog",
    "dialog_offer_data",
    "dialog_offer_summary_data",
  ],

  data() {
    return {
      error: null,
    };
  },

  methods: {
    async update_summary_offer_status(type) {
      try {
        if (type === "awarded") {
          if (window.confirm("Are you sure you want to award this offer?")) {
            let update_result = await API().patch(
              `/api/internal-admin/short-list-offer/${this.dialog_offer_data.id}?type=${type}&deal_summary_id=${this.dialog_offer_summary_data.id}`,
              {
                summary_offer_id: this.dialog_offer_data.id,
              }
            );
            console.log("Update result: ", update_result);
          } else {
            console.log("awarding cancelled");
          }
        } else {
          let update_result = await API().patch(
            `/api/internal-admin/short-list-offer/${this.dialog_offer_data.id}?type=${type}&deal_summary_id=${this.dialog_offer_summary_data.id}`,
            {
              summary_offer_id: this.dialog_offer_data.id,
            }
          );
          console.log("Update result: ", update_result);
        }

        this.$emit("reload_page_data");
        this.$emit("close_show_offer_details_dialog");
      } catch (error) {
        this.error =
          "There was an error shortlisting the offer, it was not completed.";
      }
    },
  },

  filters: {
    ...filt,
  },
};
</script>
